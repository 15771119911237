import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../views/Header/Header";
import Footer from "../views/Footer/Footer";
import Error404 from "../views/Error/Error404";

const Home = React.lazy(() => import("../views/Home/Home"));
const Login = React.lazy(() => import("../views/Login/Login"));
const SignUp = React.lazy(() => import("../views/SignUp/SignUp"));
/* Merchant */
const Orders = React.lazy(() =>
  import("../views/Dashboard/Merchant/Orders/Orders")
);
const Products = React.lazy(() =>
  import("../views/Dashboard/Merchant/Products/Products")
);
const ComboProducts = React.lazy(() =>
  import("../views/Dashboard/Merchant/ComboProducts/ComboProducts")
);
const Customers = React.lazy(() =>
  import("../views/Dashboard/Merchant/Customers/Customers")
);
const Reports = React.lazy(() =>
  import("../views/Dashboard/Merchant/Reports/Reports")
);
const Settings = React.lazy(() =>
  import("../views/Dashboard/Merchant/Settings")
);
const Shipping = React.lazy(() =>
  import("../views/Dashboard/Merchant/Shipping")
);
const MerchantDashboard = React.lazy(() =>
  import("../views/Dashboard/Merchant/MerchantDashboard")
);
const Media = React.lazy(() =>
  import("../views/Dashboard/Merchant/Media/Media")
);

/* Admin */
const AdminDashboard = React.lazy(() =>
  import("../views/Dashboard/Admin/AdminDashboard")
);
const Sales = React.lazy(() =>
  import("../views/Dashboard/Admin/Sales/Sales")
);
const AdminReports = React.lazy(() =>
  import("../views/Dashboard/Admin/Reports/Reports")
);
const AdminCustomers = React.lazy(() =>
  import("../views/Dashboard/Admin/Customers/Customers")
);
const Users = React.lazy(() =>
  import("../views/Dashboard/Admin/Users/Users")
);
const Sellers = React.lazy(() =>
  import("../views/Dashboard/Admin/Sellers/Sellers")
);
const Coupons = React.lazy(() =>
  import("../views/Dashboard/Admin/Coupons/Coupons")
);
const Categories = React.lazy(() =>
  import("../views/Dashboard/Admin/Categories/Categories")
);
const Subcategories = React.lazy(() =>
  import("../views/Dashboard/Admin/Subcategories/Subcategories")
);
const ChildCategories = React.lazy(() =>
  import("../views/Dashboard/Admin/ChildCategories/ChildCategories")
);
const AppSetting = React.lazy(() =>
  import("../views/Dashboard/Admin/AppSetting")
);
const AdminSettings = React.lazy(() =>
  import("../views/Dashboard/Admin/AdminSettings")
);
// old routes
const Error422 = React.lazy(() => import("../views/Error/Error422"));
const Error500 = React.lazy(() => import("../views/Error/Error500"));

let routes = [
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/signup",
    component: SignUp,
    exact: true,
  },
  {
    path: "/merchant-dashboard",
    component: MerchantDashboard,
    exact: true,
  },
  {
    path: "/merchant-dashboard/orders",
    component: Orders,
    exact: true,
  },
  {
    path: "/merchant-dashboard/products",
    component: Products,
    exact: true,
  },
  {
    path: "/merchant-dashboard/combo-products",
    component: ComboProducts,
    exact: true,
  },
  {
    path: "/merchant-dashboard/customers",
    component: Customers,
    exact: true,
  },
  {
    path: "/merchant-dashboard/reports",
    component: Reports,
    exact: true,
  },
  {
    path: "/merchant-dashboard/settings",
    component: Settings,
    exact: true,
  },
  {
    path: "/merchant-dashboard/shipping",
    component: Shipping,
    exact: true,
  },
  {
    path: "/merchant-dashboard/media",
    component: Media,
    exact: true,
  },
  {
    path: "/admin-dashboard",
    component: AdminDashboard,
    exact: true,
  },
  {
    path: "/admin-dashboard/sales",
    component: Sales,
    exact: true,
  },
  {
    path: "/admin-dashboard/reports",
    component: AdminReports,
    exact: true,
  },
  {
    path: "/admin-dashboard/customers",
    component: AdminCustomers,
    exact: true,
  },
  {
    path: "/admin-dashboard/sellers",
    component: Sellers,
    exact: true,
  },
  {
    path: "/admin-dashboard/coupons",
    component: Coupons,
    exact: true,
  },
  {
    path: "/admin-dashboard/users",
    component: Users,
    exact: true,
  },
  {
    path: "/admin-dashboard/categories",
    component: Categories,
    exact: true,
  },
  {
    path: "/admin-dashboard/subcategories",
    component: Subcategories,
    exact: true,
  },
  {
    path: "/admin-dashboard/child-categories",
    component: ChildCategories,
    exact: true,
  },
  {
    path: "/admin-dashboard/app-setting",
    component: AppSetting,
    exact: true,
  },
  {
    path: "/admin-dashboard/settings",
    component: AdminSettings,
    exact: true,
  },
  {
    path: "/error422",
    component: Error422,
    exact: true,
  },
  {
    path: "/error500",
    component: Error500,
    exact: true,
  },
];

const AllRoutes = (props) => {
  return (
    <>
      {/* <Header history={props.history} /> */}
      <Suspense fallback="Loading...">
        <Switch>
          {routes.map((ele, ind) => {
            return (
              <Route
                key={ind}
                history={props.history}
                isAuthed={props.history}
                {...ele}
              />
            );
          })}
        </Switch>
      </Suspense>
      {/* <Footer /> */}
    </>
  );
};

export default AllRoutes;
