import React from "react";
import ReactDOM from "react-dom";
import { Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import { createBrowserHistory } from "history";
import rootReducer from "./reducers";
// import { createStore, applyMiddleware } from 'redux'
// import { Provider } from 'react-redux'
import "./index.css";
import App from "./App";
import ErrorBoundary from "./ErrorBoundary";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
const hist = createBrowserHistory();
const store = createStore(rootReducer);

hist.listen((location) => {
  window.scrollTo(0, 0);
});
axios.defaults.headers.common["domain"] = "app.manlykart.com";

ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <Router history={hist}>
        {/* <Route> */}
        {/* <Route path="/" component={App}/> */}
        <App history={hist}></App>
        {/* </Route> */}
      </Router>
    </Provider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
