import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import "./Login.css";
import '../SignUp/OldRegister.css';
import axios from "axios";
import Cookies from "universal-cookie";
import { LOGIN, LOGIN_FAILURE, LOGOUT } from "../../actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';



const cookies = new Cookies();
class Login extends Component {
  state = {
    username: {
      data: "",
      required: true,
      errorMsg: "Required",
    },
    password: {
      data: "",
      required: true,
      errorMsg: "Required",
    },
    rememberPassword: false,
    error: "",
    formError: {},
    checked: false,
    loader: false,
  };
  handelChecked() {
    if (this.state.rememberPassword) {
      let accountInfo =
        this.state.username.data + "&" + this.state.password.data;

      let Days = 3;
      let exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      document.cookie =
        "accountInfo" +
        "=" +
        escape(accountInfo) +
        ";expires=" +
        exp.toGMTString();
    } else {
      let exp = new Date();
      exp.setTime(exp.getTime() - 1);
      let accountInfo = document.cookie;
      var cookie_pos = accountInfo.indexOf("accountInfo");

      if (cookie_pos != -1) {
        document.cookie =
          "accountInfo" + "=" + " " + ";expires=" + exp.toGMTString();
      }

      this.state.name.data = "";
      this.state.password.data = "";
    }
  }

  //判断cookie中是否有账号信息，有就可以进行预填写，没有则直接返回
  loadAccountInfo = () => {
    //读取cookie
    let arr,
      reg = new RegExp("(^| )" + "accountInfo" + "=([^;]*)(;|$)");
    let accountInfo = "";
    if ((arr = document.cookie.match(reg))) {
      accountInfo = unescape(arr[2]);
    } else {
      accountInfo = null;
    }

    if (Boolean(accountInfo) == false) {
      return false;
    } else {
      let userName = "";
      let passWord = "";

      let i = new Array();
      i = accountInfo.split("&");
      userName = i[0];
      passWord = i[1];

      this.state.username.data = userName;
      this.state.password.data = passWord;
      this.setState({
        rememberPassword: true,
      });
    }
  };

  componentDidMount() {
    let check = localStorage.getItem("checked") === "true";
    let user = localStorage.getItem("username");
    let pass = localStorage.getItem("password");
    if (user != "" && user != undefined && user != null) {
      this.state.username.data = user;
      this.state.password.data = pass;
      this.state.checked = check;
    }
    this.setState({});
    //   let userDatainCookie = cookies.get('userdata')
    //   if(userDatainCookie){
    //   if(Object.keys(userDatainCookie).length > 0){
    //       this.state.username.data = userDatainCookie.username
    //       this.state.password.data = userDatainCookie.password
    //       this.setState({
    //           username : this.state.username , password : this.state.password , checked:true
    //       });

    //   }
    // }
    //   localStorage.removeItem('user');
    //   this.props.dispatch(LOGOUT({}))
  }

  handleValidation() {
    let self = this;
    Object.keys(this.state).map(function (key) {
      if (self.state[key].required && self.state[key].data === "") {
        self.state.formError[key] = self.state[key].errorMsg
          ? self.state[key].errorMsg
          : "Required!";
      }
      self.setState(
        {
          formError: self.state.formError,
        },
        () => {
          console.log("qwerty========");
        }
      );
    });
  }

  formSubmit(e) {
    e.preventDefault();
    //console.log(cookies.get('userdata'));
    let self = this;
    self.setState({
      loader: true,
    });
    this.handleValidation();
    let payload = {};
    if (
      Object.keys(self.state.formError).length > 0 ||
      Object.keys(self.state.formError) === "undefined"
    ) {
      self.setState({
        loader: false,
      });
      return false;
    }
    Object.keys(self.state).map(function (key) {
      payload[key] = self.state[key].data;
    });
    //console.log("url is"+ process.env.REACT_APP_BASE_URL)
    let url = process.env.REACT_APP_BASE_URL;
    axios
      .post(url + "auth/merchant_login", payload)
      .then(function (response) {
        console.log("res " + response.data.status);
        if (response.data.status == "no") {
          self.setState({
            error: response.data.message,
            loader: false,
          });
          self.props.dispatch(
            LOGIN_FAILURE({
              error: response.data.message,
            })
          );
          return false;
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // window.fcWidget.user.setFirstName(response.data.username);
        // window.fcWidget.user.setEmail(response.data.userdata.email);
        let userdata =
          response && response.data && response.data.data
            ? JSON.parse(atob(response.data.data.split(".")[1]))
            : "";
        userdata.user_token = response.data.data;
        if (userdata.user_token) {
          self.setState({
            loader: false,
          });
        }
        localStorage.setItem("user", JSON.stringify(userdata));
        if (self.state.checked) {
          localStorage.setItem("checked", self.state.checked);
          localStorage.setItem("username", self.state.username.data);
          localStorage.setItem("password", self.state.password.data);
        } else {
          localStorage.setItem("username", "");
          localStorage.setItem("password", "");
        }
        //localStorage.setItem('user_token', response.data.data);
        self.props.dispatch(LOGIN(userdata));
        switch (userdata.role) {

          default:
            self.props.history.push("/merchant-dashboard");
            break;
        }
      })
      .catch(function (error) {
        self.setState({
          error: "Try later!",
        });
        return false;
      });
  }

  handleChange = (name) => (event) => {
    let preState = this.state[name];
    if (this.state.formError[name]) {
      delete this.state.formError[name];
    }
    preState.data = event.target.value;
    this.setState({
      [name]: preState,
      formError: this.state.formError,
      error: "",
    });
  };

  handleClick = (name) => (e) => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  closeToggle = (path) => (event) => {
    const { name } = event.target;
    this.setState(() => {
      this.props.history.push(path);
    });
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
            <div className="form-left">
                {/* <img src="./images/logo.svg" class="img-fluid mr-2" alt="geo" width="183.81px"></img> */}
                <img src="./images/logo-manlykart.svg" style={{'width': '350px'}} class="img-fluid mr-2" alt="geo" width="183.81px"></img>
                {/* <h1 className='login-title'>BUYKAR</h1> */}
            </div>
        </div>
        <div className="col-md-6">
            <div className='form-right'>
                <h1 className="login-subtitle">Login</h1>
                <div className='login-basic'>
                    <h1 className="login-basic-title">Please Login</h1>
                </div>
                <div className='login-form'>
                    <form className='form' onSubmit={this.formSubmit.bind(this)}>
                        <div className="login-form-item">
                            <div className="login-form-input-label">
                                <label className="login-form-label">Username</label>
                                <input 
                                    type='text'
                                    className="login-form-input"
                                    value={this.state.username.data}
                                    onChange={this.handleChange('username')}
                                    autoFocus
                                />
                                <span style={{ color: "red" }}>
                                {this.state.formError["username"]}
                                {this.state.error}
                                </span>
                            </div>
                            <div className="login-form-input-label">
                                <label className="login-form-label">Password</label>
                                <input 
                                    type='password'
                                    className="login-form-input"
                                    value={this.state.password.data}
                                    onChange={this.handleChange('password')}
                                />
                                <span style={{ color: "red" }}>
                                {this.state.formError["password"]}
                                </span>
                            </div>
                        </div>
                        <span>Don't have an account? <Link to='/signup' className="login-singup-btn">Sign Up Here!</Link></span>
                        <div className='login-btn-container'>
                            <button
                                className="login-btn"
                                type="submit"
                            >
                                Login
                            </button>
                        </div>
                       
                        
                    </form>
                </div>
                <div className='d-block d-md-none text-center mt-2'>
                    <h6>Already have an account ?</h6>
                    <Link to='/login'>Login now </Link>
                </div>
            </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}

export default connect(mapStateToProps)(Login);
