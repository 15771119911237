import React, { Component, useEffect, useState } from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { Link } from "react-router-dom";
import "./Header.css";
import "../Dashboard/Dashboard.css"
import { connect } from "react-redux";
import NotesIcon from "@material-ui/icons/Notes";
import PushNotification from "../../components/PushNotification/PushNotification";
import {
  NOTIFICATION,
  LOGIN,
  LOGIN_FAILURE,
  LOGOUT,
  updateCart,
} from "../../actions";
// import { StickyContainer, Sticky } from 'react-sticky';
import { log } from "util";
import Service from "../../Service/Service";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import {ReactComponent as IconBell} from '../../Assets/bell.svg';
import {ReactComponent as IconAvatar} from '../../Assets/avatar.svg';
import {ReactComponent as Logo} from '../../Assets/logo/logo-manlykart-transparent.svg';



const pStyle = {
  textAlign: "center",
};
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiledrop: "profiledrop",
      profiledropToggle: false,
      menulist: "",
      pwaToast: false,
      dateTime:'sadas',
      dropdown: false
    };
  }

  state = { showSideNav: false };

  myAccountNav = () => {
    this.setState({
      profiledropToggle: !this.state.profiledropToggle,
    });
  };
  snackBarClose = (e) => {
    this.props.dispatch(NOTIFICATION({ msg: "" }));
  };
  clickToRedirect = (event) => {
    this.onresclick();
    let prop = this.props;
    let link =
      // event.target.name === "logoutLink" ?
      "/login";
    //  : '';
    this.setState(
      {
        profiledropToggle: false,
      },
      () => {
        localStorage.setItem("user", "");
        // localStorage.setItem('username', '');
        // localStorage.setItem('password', '');
        localStorage.setItem("draft", 0);
        prop.dispatch(LOGOUT({}));
        // this.props.history.push(link);
      }
    );
  };
  closeToggle = (path) => (event) => {
    const { name } = event.target;
    // if(path=="/search") {
    //   console.log("search page");
    //   window.location.href="/search";
    // }
    // this.setState(path);
    this.setState(() => {
      if (
        path != "/processordashboard/message" &&
        path != "/buyerdashboard/message" &&
        path != "/sellerdashboard/message"
      ) {
        path && this.props.history.push(path);
      } else {
        this.props.history.push({
          pathname: path,
          state: { msgUserid: "admin" },
        });
      }
    });
  };
  handleBlur = () => {
    this.setState({
      profiledropToggle: false,
    });
  };

  // addDefaultSrc(ev){
  //   console.log("addd");
  //   let url = process.env.REACT_APP_BASE_URL;
  //   ev.target.src = url+"uploads/No_Image_Available.jpg"
  // }  // onscroll

  getCart = async () => {
    //setLoader(true);
    try {
      let {
        user: { user_token },
        dispatch,
      } = this.props;
      const res = await new Service(user_token).get(
        "cart/getUserCart",
        {}
      );
      if (res.data.status) {
        let cart_data = res.data.data.filter((val) => parseInt(val.r_id) == 0);
        this.props.dispatch(
          updateCart({
            count: cart_data.length,
            data: cart_data,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  getDatetime =  () => {
    const date = new Date(); // You can replace this with your desired date

    const options = {
      month: 'short', // Display month as abbreviated string (e.g., "Mar")
      day: 'numeric', // Display day as numeric value (e.g., "23")
      year: 'numeric', // Display year as numeric value (e.g., "2023")
      hour: '2-digit', // Display hour in 12-hour format (e.g., "02")
      minute: '2-digit', // Display minute (e.g., "30")
      hour12: true, // Use 12-hour format (e.g., "AM/PM")
    };

    const formattedDateTime = date.toLocaleString('en-US', options);

    this.setState({dateTime:formattedDateTime});

  };
  image_url = () => {
    // console.log(' props user',this.props.user)
    return typeof this.props.user.business_logo!== 'undefined' && this.props.user.business_logo!== null && this.props.user.business_logo.file_name ? process.env.REACT_APP_ROOT_URL+"uploads/"+`${this.props.user.business_logo.file_name}`  : "/images/user-icon.svg"
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let activeClass = "normal";
      if (window.scrollY === 0) {
        activeClass = "top";
      }
      this.setState({ activeClass });
    });
    this.getCart();
  }
  // end
  // leftmenu

  onresclick = (e) => {
    // if (this.state.menulist == "add") {
    //   this.setState({ menulist: "" });
    // } else {
    //   this.setState({ menulist: "add" });
    // }
  };

  componentWillMount() {
    // this.unlisten = this.props.history.listen((location, action) => {
    //   this.setState({ menulist: '' });
    // });
    this.getDatetime();
  }
  componentWillUnmount() {
    // this.unlisten();
  }

  pwaFunction = () => {
    console.log("entered pwa function inititated");
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(userAgent)) {
        if (/chrome|crios/.test(userAgent)) {
          return false;
        } else if ("brave" in window.navigator) {
          return false;
        } else {
          return /safari/.test(userAgent);
        }
      }
    };

    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.setState({ pwaToast: true });
      this.setState({ showInstallMessage: true });
      // SetPwaToast(true)
    }
  };

  componentDidMount() {
    console.log("pwa function inititated");
    this.pwaFunction();
  }

  // leftmenu end
  render() {
    let { user, loggedIn, msg, msgLink, msgColor, classs, cartData } =
      this.props;
    let img_url = process.env.REACT_APP_IMAGE_URL;
    let url = process.env.REACT_APP_BASE_URL;

    return (
      <div className="G-zr">
        {/* <StickyCompenet {...this.props} /> */}
        <header
          className={`fixed-top Af-log ${this.state.activeClass} ${classs}`}
        >
          <nav className="header">
            <Link className="header-logo" to="/">
              <img
                // src="/images/logo-white.svg"
                src="/images/logo-manlykart.png"
                className="header-logo-image"
                alt="LOGO"
              />
              {/* <Logo /> */}
            </Link>

            <div className="header-menu">
              {/* <FontAwesomeIcon className="header-menu-item" icon={faBell} /> */}
              {/* <FontAwesomeIcon className="header-menu-item" icon={faBell} />
              <FontAwesomeIcon onClick={() => this.setState({dropdown:!this.state.dropdown})} className="header-menu-item" icon={faEllipsisV} /> */}
             
              <IconBell className="header-menu-item" />
              {/* <IconAvatar onClick={() => this.setState({dropdown:!this.state.dropdown})} className="header-menu-item" /> */}
              
              <figure className="header-menu-item" onClick={() => this.setState({dropdown:!this.state.dropdown})} >
                  <img src={this.image_url()} className="img-avatar" alt="profile" />
              </figure>

              {
              this.state.dropdown ? 
                <a
                  href="/login"
                  name="logoutLink"
                  style={{position:'absolute', right:0}}
                  onClick={() => this.clickToRedirect()}
                >
                  <div className="header-dropdown">Logout</div> 
                </a>
                : 
                ''
              }
            </div>
            
            
            {msg && (
              <PushNotification
                msgColor={msgColor}
                msgLink={msgLink}
                msg={msg}
                snackBarClose={this.snackBarClose}
              />
            )}
            {this.state.pwaToast && 
            <div className="installPWA">
              <div className="inrFlx">
                <img src="./images/iosadd.svg" alt="add_icon" className="addIconImg"/>
                <p>Install the webapp on your iPhone: tap <img src="./images/iosupload.svg" alt="menu_icon" className="mnuIconImg"/> and then Add to homescreen</p>
              </div>
              <Button onClick={() => this.setState({pwaToast:false})}>Okay</Button>
            </div>
            } 
          </nav>
        </header>
      </div>
    );
  }
}
function MaterialToggle(props) {
  const size = useWindowSize();
  const [material, setMaterial] = useState();
  useEffect(() => {
    if (size.width >= 992) {
      setMaterial();
    }
  });
  return (
    <>
      <button
        className="navbar-toggler"
        type="button"
        onClick={(e) => setMaterial(true)}
      >
        <span className="navbar-toggler-icon" />
        <NotesIcon style={pStyle} />
      </button>
      <SwipeableDrawer open={material}>
        <ul>
          <li>first lement</li>
          <li>second lement</li>
          <li>thrid lement</li>
        </ul>
      </SwipeableDrawer>
    </>
  );
}
function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
function mapStateToProps(state) {
  const { user, loggedIn, class: classs } = state.login;
  const { msg, msgColor, msgLink } = state.header;
  const { cartData } = state.Cart;

  return { user, loggedIn, msg, msgColor, msgLink, cartData, classs };
}

export default connect(mapStateToProps)(Header);
