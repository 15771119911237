import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import "./Login.css";
import '../SignUp/SignUp.css';
import '../SignUp/OldRegister.css';
import axios from "axios";
import Cookies from "universal-cookie";
import { LOGIN, LOGIN_FAILURE, LOGOUT } from "../../actions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { ReactComponent as IconArrow } from '../../Assets/arrow-right.svg';
import { Alert } from "react-bootstrap";
import {ReactComponent as Logo} from '../../Assets/logo/logo-manlykart-transparent.svg';
// import {ReactComponent as Logo} from '../../Assets/logo/buykar-logo.svg';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'


const cookies = new Cookies();
class Login extends Component {
  state = {
    field: {
      id: null,
      email: '',
      otp: '',
      phone: null,
      phoneCountry: 'IN',
    },
    formValidate: false,
    error: "",
    otp_requested: false,
    checked: false,
    loader: false,

    validation_1: {
      phone: false,
    },
    loading: false,
  };

  loadingStyle = {
    pointerEvents: 'none',
    opacity: 0.4,
  }

  constructor(props) {
    super(props);

    this.formRef = React.createRef();
    this.otp1Ref = React.createRef();
    this.otp2Ref = React.createRef();
    this.otp3Ref = React.createRef();
    this.otp4Ref = React.createRef();
  }

  fieldChange = (e) => {
    let field = this.state.field;

    field[e.target.name] = e.target.value;

    this.setState({
      field
    })
  }

  changePhoneCountry = (country_code) => {
    let field = this.state.field;

    field.phoneCountry = country_code;

    this.setState({
      field,
    })
  }

  changePhoneNumber = (value) => {
    let validation_1 = this.state.validation_1;
    let field = this.state.field;


    if (value === '') {
      validation_1.phone = false;
    } else {
      try {
        console.log(this.state.field.phoneCountry, value, value.length, isValidPhoneNumber(value))
        if (isValidPhoneNumber(value)) {

          if (this.state.field.phoneCountry === 'IN') {
            if (value.length > (10 + 3)) {
              console.log('invalid')
              validation_1.phone = false;
            } else {
              validation_1.phone = true;
              field.phone = value;
            }
          } else {
            validation_1.phone = true;
            field.phone = value;
          }
        } else {
          validation_1.phone = false;
        }
        
        this.setState({
          field,
          validation_1
        })
      } catch (error) {
        validation_1.phone = false;
        this.setState({
          validation_1
        })
        console.log(error)
      }
    }
  }

  otpChange = (e) => {
    let field = this.state.field;

    field[e.target.name] = e.target.value;

    this.setState({
      field
    })

    if (field.otp_code_1 && field.otp_code_2 && field.otp_code_3 && field.otp_code_4) {
      field.otp = `${field.otp_code_1}${field.otp_code_2}${field.otp_code_3}${field.otp_code_4}`
      this.setState({
        field
      })

      this.otpSubmit();
    }

    switch(e.target.name){
      case "otp_code_1": this.otp2Ref.current.focus(); break;
      case "otp_code_2": this.otp3Ref.current.focus(); break;
      case "otp_code_3": this.otp4Ref.current.focus(); break;
      // case "otp_code_4": this.otp4Ref.current.unfocus(); break;
    }
  }

  formValidity = () => {
    let isValid = true;

    Object.keys(this.state.validation_1).map((key) => {
      isValid = this.state.validation_1[key] && isValid;
    })

    return isValid;
  }

  otpRequest = (e) => {

    e.preventDefault();

    this.setState({
      formValidate: true,
      loading: true,
    })

    // const form = e.currentTarget;

    if (this.formValidity() === false) {
      // e.stopPropagation();
    }

    if (this.formValidity() === true) {

      let self = this;

      const data = new FormData();
      let {
        phone,
      } = this.state.field;

      data.append('phone', phone)

      let url = process.env.REACT_APP_BASE_URL;
      // const headers = {
      //   'Origin': 'app.manlykart.com',
      //   'Content-Type': 'application/json'
      // }
      axios
        .post(url + 'auth/otp_login', data)
        .then(function (response) {
          if (response.data.status === 'no') {
            console.log(response);
            self.setState({
              error: response.data.message,
              loading: false,
            });
            return false;
          } else if (response.data.status === 'yes') {

            let field = self.state.field;
            field.id = response.data.data.id

            self.setState({
              field,
              otp_requested: true,
              error: false,
              loading: false,
            })

          }
        })
        .catch(function (error) {
          self.setState({
            error: error.message,
            loading: false,
          });
          return false;
        });

    }else{
      this.setState({
        loading: false
      })
    }
  }

  otpSubmit = () => {

    this.setState({
      loading: true,
    })

    const form = this.formRef.current;

    if (form.checkValidity() === false) {
      form.stopPropagation();
    }

    if (form.checkValidity() === true) {

      let self = this;

      const data = new FormData();
      let {
        id,
        otp,
      } = this.state.field;

      data.append('id', id)
      data.append('otp', otp)

      let url = process.env.REACT_APP_BASE_URL;
      // const headers = {
      //   'Origin': 'app.manlykart.com',
      //   'Content-Type': 'application/json'
      // }
      axios
        .post(url + 'auth/otp_verify', data)
        .then(function (response) {
          if (response.data.status === 'no') {
            console.log(response);
            self.setState({
              error: response.data.message,
              loading: false,
            });
            return false;
          } else if (response.data.status === 'yes') {

            //auto login user
            let userdata =
              response && response.data && response.data.data
                ? JSON.parse(atob(response.data.data.split(".")[1]))
                : "";
            userdata.user_token = response.data.data;
            if (userdata.user_token) {
              self.setState({
                loader: false,
                loading: false,
              });
            }
            localStorage.setItem("user", JSON.stringify(userdata));
            self.props.dispatch(LOGIN(userdata));
            switch (userdata.role) {
              case "administrator":
              case "super_admin":
                  setTimeout(() => {
                    self.props.history.push("/admin-dashboard");
                  }, 0);
                  break;
              default:
                  setTimeout(() => {
                    self.props.history.push("/merchant-dashboard");
                  }, 0);
                break;
            }

          }
        })
        .catch(function (error) {
          self.setState({
            error: error.message,
            loading: false,
          });
          return false;
        });

    }else{
      this.setState({
        loading: false,
      })
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-left">
            {/* <img src="./images/logo.svg" className="img-fluid mr-2" alt="geo" width="183.81px"></img> */}
            {/* <img src="./images/logo-manlykart.svg" style={{'width': '350px'}} className="img-fluid mr-2" alt="geo" width="183.81px"></img> */}
             <Logo className="logo" /> 
            {/* <h1 className='login-title'>BUYKAR</h1> */}
          </div>
        </div>
        <div className="col-md-6">
          <div className='form-right login-wrap'>
            <div style={this.state.loading ? this.loadingStyle : null} className='login-form'>
              <Form ref={this.formRef} noValidate validated={this.state.formValidate} className="" onSubmit={this.otpRequest.bind(this)}>
                <div className="login-form-item row">
                  <Form.Group className="col-md-12" controlId="phone">
                    <Form.Label className="signup-form-label">Mobile<span className="signup-form-label-required">*</span></Form.Label>
                    {/* <Form.Control autoFocus={true} type="text" placeholder="" name="phone" required={true} value={this.state.field.phone} onChange={this.fieldChange} /> */}
                    <PhoneInput
                        placeholder=""
                        className={`form-control ${this.state.formValidate && this.state.validation_1.phone ? 'is-valid' : (this.state.formValidate ? 'is-invalid' : '')}`}
                        value={this.state.field.phone}
                        defaultCountry={this.state.field.phoneCountry}
                        onCountryChange={(value) => this.changePhoneCountry(value)}
                        rules={{ required: true }}
                        onChange={(value) => this.changePhoneNumber(value)} />
                    <Form.Control.Feedback type="invalid">
                      Please enter valid mobile number
                    </Form.Control.Feedback>
                  </Form.Group>
                  {this.state.otp_requested && <>
                  <div className="col-md-8">
                    <div className="otp-wrap row">
                      <Form.Group className="col-3 pr-0" controlId="otp">
                        <Form.Control ref={this.otp1Ref} className="text-center" type="text" placeholder="" name="otp_code_1" value={this.state.field.otp_code_1} onChange={this.otpChange} />
                      </Form.Group>
                      <Form.Group className="col-3 pr-0" controlId="otp">
                        <Form.Control ref={this.otp2Ref} className="text-center" type="text" placeholder="" name="otp_code_2" value={this.state.field.otp_code_2} onChange={this.otpChange} />
                      </Form.Group>
                      <Form.Group className="col-3 pr-0" controlId="otp">
                        <Form.Control ref={this.otp3Ref} className="text-center" type="text" placeholder="" name="otp_code_3" value={this.state.field.otp_code_3} onChange={this.otpChange} />
                      </Form.Group>
                      <Form.Group className="col-3 pr-0" controlId="otp">
                        <Form.Control ref={this.otp4Ref} className="text-center" type="text" placeholder="" name="otp_code_4" value={this.state.field.otp_code_4} onChange={this.otpChange} />
                      </Form.Group> 
                    </div></div></>}
                  {this.state.error &&
                    <Form.Group className="col-md-12" controlId="alert">
                      <Alert className="mt-3" variant="danger">{this.state.error}</Alert>
                    </Form.Group>}
                </div>
                <div className='login-btn-container'>
                  <button
                    className="login-btn"
                    type="submit"
                    disabled={this.state.otp_requested}
                  >
                    Login <IconArrow />
                  </button>
                </div>
                <div className="mt-4">Don't have an account? <Link to='/signup' className="login-singup-btn">Sign Up Here!</Link></div>

              </Form>
            </div>
            {/* <div className='d-block d-md-none text-center mt-2'>
              <h6>Already have an account ?</h6>
              <Link to='/login'>Login now </Link>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}

export default connect(mapStateToProps)(Login);
